import "./src/styles/global.css"

import { initializeApp } from "firebase/app";
let firebaseConfigProd= {
    apiKey: "AIzaSyCkou-ERAvREgfsg72yYCCQIlPR-0j8QAk",
    authDomain: "paddleqr.com",
    projectId: "paddle-qr",
    storageBucket: "paddle-qr.appspot.com",
    messagingSenderId: "179379081282",
    appId: "1:179379081282:web:aa504043e5aa54bf469ddb",
    measurementId: "G-WM21LGPVP6"
  };
  const firebaseConfigDev = {
      apiKey: "AIzaSyB3dXIz3oE20qeoOrjOqnfZ7ByeHJwUS5Y",
      authDomain: "new.paddleqr.com",
      projectId: "paddleqrdev",
      storageBucket: "paddleqrdev.appspot.com",
      messagingSenderId: "137430913462",
      appId: "1:137430913462:web:d48acc2da842717de8b117"
    };
  
  // Initialize Firebase
  if(process.env.NODE_ENV ==="production"){
      initializeApp(firebaseConfigProd);
      // const app = firebase.initializeApp(firebaseConfigProd);
      // const analytics = firebase.analytics(app)  //getAnalytics(app);
      // return firebase.auth()
  }
  else{
      initializeApp(firebaseConfigDev);
      //console.log(getApp()
      // firebase.initializeApp(firebaseConfigDev)
      
      // return firebase.auth()
  }
